import { default as _91_46_46_46slug_93sctl4T5yyMMeta } from "/var/www/projects/dev2.hillceramic.se/releases/20241003122701Z/pages/[...slug].vue?macro=true";
import { default as _91_46_46_46slug_93CAsqAkNIihMeta } from "/var/www/projects/dev2.hillceramic.se/releases/20241003122701Z/pages/blogg/[...slug].vue?macro=true";
import { default as indexE0fkv0VH3NMeta } from "/var/www/projects/dev2.hillceramic.se/releases/20241003122701Z/pages/blogg/index.vue?macro=true";
import { default as indexIxyw6wlcOHMeta } from "/var/www/projects/dev2.hillceramic.se/releases/20241003122701Z/pages/checkout/index.vue?macro=true";
import { default as paymentOkCd4H6O5iMeta } from "/var/www/projects/dev2.hillceramic.se/releases/20241003122701Z/pages/checkout/payment.vue?macro=true";
import { default as index3kFrLPZDs9Meta } from "/var/www/projects/dev2.hillceramic.se/releases/20241003122701Z/pages/checkout/thank-you/index.vue?macro=true";
import { default as indexFXBgc73TnqMeta } from "/var/www/projects/dev2.hillceramic.se/releases/20241003122701Z/pages/collections/index.vue?macro=true";
import { default as _91slug_93IdxDr372gnMeta } from "/var/www/projects/dev2.hillceramic.se/releases/20241003122701Z/pages/faq/[slug].vue?macro=true";
import { default as indexHd4hnf6XFfMeta } from "/var/www/projects/dev2.hillceramic.se/releases/20241003122701Z/pages/hillceramic-rabattkoder/index.vue?macro=true";
import { default as indexPzdwsBvATsMeta } from "/var/www/projects/dev2.hillceramic.se/releases/20241003122701Z/pages/index.vue?macro=true";
import { default as indexsZfNv688JrMeta } from "/var/www/projects/dev2.hillceramic.se/releases/20241003122701Z/pages/login/index.vue?macro=true";
import { default as _91id_93487REW4t9KMeta } from "/var/www/projects/dev2.hillceramic.se/releases/20241003122701Z/pages/qr-pr/[id].vue?macro=true";
import { default as _91_46_46_46slug_93J0h0wuDnOnMeta } from "/var/www/projects/dev2.hillceramic.se/releases/20241003122701Z/pages/shop/[...slug].vue?macro=true";
import { default as indexbMxdJLHYjUMeta } from "/var/www/projects/dev2.hillceramic.se/releases/20241003122701Z/pages/track/index.vue?macro=true";
export default [
  {
    name: "slug___sv",
    path: "/:slug(.*)*",
    component: () => import("/var/www/projects/dev2.hillceramic.se/releases/20241003122701Z/pages/[...slug].vue")
  },
  {
    name: "slug___da",
    path: "/da/:slug(.*)*",
    component: () => import("/var/www/projects/dev2.hillceramic.se/releases/20241003122701Z/pages/[...slug].vue")
  },
  {
    name: "blogg-slug___sv",
    path: "/blogg/:slug(.*)*",
    component: () => import("/var/www/projects/dev2.hillceramic.se/releases/20241003122701Z/pages/blogg/[...slug].vue")
  },
  {
    name: "blogg-slug___da",
    path: "/da/blogg/:slug(.*)*",
    component: () => import("/var/www/projects/dev2.hillceramic.se/releases/20241003122701Z/pages/blogg/[...slug].vue")
  },
  {
    name: "blogg___sv",
    path: "/blogg",
    component: () => import("/var/www/projects/dev2.hillceramic.se/releases/20241003122701Z/pages/blogg/index.vue")
  },
  {
    name: "blogg___da",
    path: "/da/blogg",
    component: () => import("/var/www/projects/dev2.hillceramic.se/releases/20241003122701Z/pages/blogg/index.vue")
  },
  {
    name: "checkout___sv",
    path: "/checkout",
    meta: indexIxyw6wlcOHMeta || {},
    component: () => import("/var/www/projects/dev2.hillceramic.se/releases/20241003122701Z/pages/checkout/index.vue")
  },
  {
    name: "checkout___da",
    path: "/da/checkout",
    meta: indexIxyw6wlcOHMeta || {},
    component: () => import("/var/www/projects/dev2.hillceramic.se/releases/20241003122701Z/pages/checkout/index.vue")
  },
  {
    name: "checkout-payment___sv",
    path: "/checkout/payment",
    meta: paymentOkCd4H6O5iMeta || {},
    component: () => import("/var/www/projects/dev2.hillceramic.se/releases/20241003122701Z/pages/checkout/payment.vue")
  },
  {
    name: "checkout-payment___da",
    path: "/da/checkout/payment",
    meta: paymentOkCd4H6O5iMeta || {},
    component: () => import("/var/www/projects/dev2.hillceramic.se/releases/20241003122701Z/pages/checkout/payment.vue")
  },
  {
    name: "checkout-thank-you___sv",
    path: "/checkout/thank-you",
    meta: index3kFrLPZDs9Meta || {},
    component: () => import("/var/www/projects/dev2.hillceramic.se/releases/20241003122701Z/pages/checkout/thank-you/index.vue")
  },
  {
    name: "checkout-thank-you___da",
    path: "/da/checkout/thank-you",
    meta: index3kFrLPZDs9Meta || {},
    component: () => import("/var/www/projects/dev2.hillceramic.se/releases/20241003122701Z/pages/checkout/thank-you/index.vue")
  },
  {
    name: "collections___sv",
    path: "/kollektioner",
    component: () => import("/var/www/projects/dev2.hillceramic.se/releases/20241003122701Z/pages/collections/index.vue")
  },
  {
    name: "collections___da",
    path: "/da/samlinger",
    component: () => import("/var/www/projects/dev2.hillceramic.se/releases/20241003122701Z/pages/collections/index.vue")
  },
  {
    name: "faq-slug___sv",
    path: "/faq/:slug()",
    component: () => import("/var/www/projects/dev2.hillceramic.se/releases/20241003122701Z/pages/faq/[slug].vue")
  },
  {
    name: "faq-slug___da",
    path: "/da/faq/:slug()",
    component: () => import("/var/www/projects/dev2.hillceramic.se/releases/20241003122701Z/pages/faq/[slug].vue")
  },
  {
    name: "hillceramic-rabattkoder___sv",
    path: "/hillceramic-rabattkoder",
    component: () => import("/var/www/projects/dev2.hillceramic.se/releases/20241003122701Z/pages/hillceramic-rabattkoder/index.vue")
  },
  {
    name: "hillceramic-rabattkoder___da",
    path: "/da/hillceramic-rabattkoder",
    component: () => import("/var/www/projects/dev2.hillceramic.se/releases/20241003122701Z/pages/hillceramic-rabattkoder/index.vue")
  },
  {
    name: "index___sv",
    path: "/",
    component: () => import("/var/www/projects/dev2.hillceramic.se/releases/20241003122701Z/pages/index.vue")
  },
  {
    name: "index___da",
    path: "/da",
    component: () => import("/var/www/projects/dev2.hillceramic.se/releases/20241003122701Z/pages/index.vue")
  },
  {
    name: "login___sv",
    path: "/login",
    component: () => import("/var/www/projects/dev2.hillceramic.se/releases/20241003122701Z/pages/login/index.vue")
  },
  {
    name: "login___da",
    path: "/da/login",
    component: () => import("/var/www/projects/dev2.hillceramic.se/releases/20241003122701Z/pages/login/index.vue")
  },
  {
    name: "qr-pr-id___sv",
    path: "/qr-pr/:id()",
    component: () => import("/var/www/projects/dev2.hillceramic.se/releases/20241003122701Z/pages/qr-pr/[id].vue")
  },
  {
    name: "qr-pr-id___da",
    path: "/da/qr-pr/:id()",
    component: () => import("/var/www/projects/dev2.hillceramic.se/releases/20241003122701Z/pages/qr-pr/[id].vue")
  },
  {
    name: "shop-slug___sv",
    path: "/shop/:slug(.*)*",
    component: () => import("/var/www/projects/dev2.hillceramic.se/releases/20241003122701Z/pages/shop/[...slug].vue")
  },
  {
    name: "shop-slug___da",
    path: "/da/shop/:slug(.*)*",
    component: () => import("/var/www/projects/dev2.hillceramic.se/releases/20241003122701Z/pages/shop/[...slug].vue")
  },
  {
    name: "track___sv",
    path: "/track",
    meta: indexbMxdJLHYjUMeta || {},
    component: () => import("/var/www/projects/dev2.hillceramic.se/releases/20241003122701Z/pages/track/index.vue")
  },
  {
    name: "track___da",
    path: "/da/track",
    meta: indexbMxdJLHYjUMeta || {},
    component: () => import("/var/www/projects/dev2.hillceramic.se/releases/20241003122701Z/pages/track/index.vue")
  }
]