
// @ts-nocheck
import locale__var_www_projects_dev2_hillceramic_se_releases_20241003122701Z_locales_sv_SE_json from "../locales/sv_SE.json";
import locale__var_www_projects_dev2_hillceramic_se_releases_20241003122701Z_locales_da_DK_json from "../locales/da_DK.json";


export const localeCodes =  [
  "sv",
  "da"
]

export const localeLoaders = {
  "sv": [{ key: "../locales/sv_SE.json", load: () => Promise.resolve(locale__var_www_projects_dev2_hillceramic_se_releases_20241003122701Z_locales_sv_SE_json), cache: true }],
  "da": [{ key: "../locales/da_DK.json", load: () => Promise.resolve(locale__var_www_projects_dev2_hillceramic_se_releases_20241003122701Z_locales_da_DK_json), cache: true }]
}

export const vueI18nConfigs = [
  
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "",
  "locales": [
    {
      "code": "sv",
      "language": "sv-SE",
      "files": [
        "/var/www/projects/dev2.hillceramic.se/releases/20241003122701Z/locales/sv_SE.json"
      ]
    },
    {
      "code": "da",
      "language": "da-DK",
      "files": [
        "/var/www/projects/dev2.hillceramic.se/releases/20241003122701Z/locales/da_DK.json"
      ]
    }
  ],
  "defaultLocale": "sv",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix_except_default",
  "lazy": false,
  "langDir": "locales",
  "detectBrowserLanguage": {
    "alwaysRedirect": false,
    "cookieCrossOrigin": false,
    "cookieDomain": null,
    "cookieKey": "i18n_redirected",
    "cookieSecure": false,
    "fallbackLocale": "",
    "redirectOn": "root",
    "useCookie": true
  },
  "differentDomains": false,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "config",
  "pages": {
    "collections/index": {
      "sv": "/kollektioner",
      "da": "/samlinger"
    }
  },
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "multiDomainLocales": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "code": "sv",
    "language": "sv-SE",
    "files": [
      {
        "path": "/var/www/projects/dev2.hillceramic.se/releases/20241003122701Z/locales/sv_SE.json"
      }
    ]
  },
  {
    "code": "da",
    "language": "da-DK",
    "files": [
      {
        "path": "/var/www/projects/dev2.hillceramic.se/releases/20241003122701Z/locales/da_DK.json"
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
